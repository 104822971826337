import React from 'react'
import './style.css'
const Link = ({ title, url }) => {
    return (
        <a href={url} target='_blank' className='link' rel="noreferrer">
            <div className="title"> {title}</div>
            <div className="icon">
                <i className="fas fa-chevron-right"></i>
            </div>
        </a>
    )
}
export default Link