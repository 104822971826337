import React from 'react'
import './style.css'
const VideoCard = ({ title, link, image, subtitle }) => {
    return (
        <div className='card'>
            <div className='img'>
                <img src={image} alt={title} />
            </div>
            <div className='info'>
                <div className='title'>
                    {title}
                </div>
                <div className='subtitle'>
                    {subtitle}
                </div>
            </div>
            <div className='btnHolder'>
                <a
                    href={link}
                    className="btn btn-success"
                    target='_blank'
                    rel="noreferrer"
                >Watch Now</a>
            </div>

        </div>
    )
}

export default VideoCard
