import React from 'react'
import './style.css'
const EventCard = ({ title, date, time, link, tags, active, image }) => {

    return (
        <div className='card'>
            <div className='img'>
                <img src={image} alt={title} />
            </div>
            <div className='info'>
                <div className='datetime'>
                    <i class="fas fa-calendar-check"></i>&nbsp; {date}
                    <br />
                    <i class="fas fa-clock"></i>&nbsp; {time}
                </div>
            </div>
            <div className='btnHolder'>
                {active ?
                    (
                        <a href={link} className="btn btn-success">Join Now</a>
                    ) :
                    (
                        <a href="https://doco.link/nation-whatsapp" className="btn btn-primary">Get Notified</a>
                    )
                }
            </div>

        </div>
    )
}

export default EventCard
