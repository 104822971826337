import React from 'react';
import Header from './components/Header'
import Events from './components/Events';
import Links from './components/Links/index';

function App() {
  return (
    <div className="App">
      <Header />
      <Events />
      <Links />
    </div>
  );
}

export default App;
