import React, { useEffect, useState } from 'react'
import './style.css'
import { db } from './../../firebaseConfig';
import EventCard from './EventCard/index';
import VideoCard from './VideoCard/index';
const Events = () => {
    const tags = ['Upcoming Events', 'Previous Events']
    const [selectedTag, setSelectedTag] = useState('Upcoming Events')
    const [events, setEvents] = useState([])
    const [videos, setVideos] = useState([])

    useEffect(() => {
        db.collection('events').orderBy('datetime', 'asc').limit(3).onSnapshot(
            snapshot => {
                const filter = snapshot.docs.filter(
                    doc => {
                        if (doc.data().status === 'Published' || doc.data().status === 'Live') {
                            return {
                                id: doc.id,
                                details: doc.data()
                            }
                        }
                        return null
                    }
                )
                setEvents(filter.map(
                    doc => ({
                        id: doc.id,
                        details: doc.data()
                    })
                ))
            }
        )
        db.collection('ytVids').orderBy('published', 'desc').limit(3).onSnapshot(
            snapshot => {
                setVideos(snapshot.docs.map(
                    doc => ({ id: doc.id, details: doc.data() })
                ))
            }
        )
        if (events.length === 0) {
            setSelectedTag('Previous Events')
        } else {
            setSelectedTag('Upcoming Events')
        }
    }, [events.length])
    return (
        <div className='events'>
            <div className="tags">
                {
                    tags.map((tag, index) => (
                        <div
                            key={index}
                            className={tag === selectedTag ? 'tag selected' : 'tag'}
                            onClick={e => setSelectedTag(tag)}
                        >
                            {tag}
                        </div>
                    ))
                }

            </div>
            {
                selectedTag === 'Upcoming Events' && (
                    <div className="upcomingEvents">
                        {
                            events.map(({ id, details }) => {
                                const eventDate = details.datetime.toDate()
                                const date = eventDate.toDateString()
                                const hours =
                                    eventDate.getHours() > 12
                                        ?
                                        eventDate.getHours() - 12
                                        :
                                        eventDate.getHours()
                                const minutes =
                                    eventDate.getMinutes() < 10
                                        ?
                                        '0' + eventDate.getMinutes()
                                        :
                                        eventDate.getMinutes()
                                const ampm = eventDate.getHours() >= 12 ? 'PM' : 'AM'
                                return (<EventCard
                                    id={id}
                                    title={details.title}
                                    subtitle={details.subtitle}
                                    active={(details.status === 'Live')}
                                    date={date}
                                    time={
                                        (`${hours}:${minutes} ${ampm} (IST)`)
                                    }
                                    image={details.image}
                                    link={details.eventLink}
                                    tags={details.tags}
                                    key={id}
                                />
                                )
                            })
                        }
                        {
                            events.length === 0 ?
                                (
                                    <div className="seeMore">
                                        <div
                                            onClick={e => setSelectedTag('Previous Events')}
                                            className="link"
                                            target='_blank'
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <div className="emoji">
                                                <img src="/assets/emoji.png" alt="Emoji" width='30px' />
                                            </div>
                                            <div className="icon"
                                                style={{
                                                    fontSize: '26px'
                                                }}
                                            >

                                                🥲
                                            </div>
                                            {/*Nothing here! Check Previous Events
                                                Someone's getting Fired*/}
                                            Having Chai-Biscuit,
                                            <br />
                                            Come back later!
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <a
                                        href="https://doconation.com/events"
                                        className="seeMore"
                                        target='_blank'
                                        rel="noopener noreferrer"
                                    >
                                        <div className="link">

                                            <div className="icon">
                                                <i className="fas fa-chevron-right"></i>
                                            </div>
                                            See More
                                        </div>
                                    </a>
                                )
                        }

                    </div>
                )
            }
            {
                selectedTag === 'Previous Events' && (
                    <div className="previousEvents">
                        {
                            videos.map(({ id, details }) => (
                                <VideoCard
                                    image={details.image}
                                    title={details.title}
                                    subtitle={details.subtitle}
                                    link={details.link}
                                    key={id}
                                />
                            ))
                        }
                        <a
                            href="https://doconation.com/library"
                            target='_blank'
                            rel="noopener noreferrer"
                            className="seeMore">
                            <div
                                className="link"
                            >
                                <div className="icon">
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                                See More
                            </div>
                        </a>
                    </div>
                )
            }


        </div>
    )
}

export default Events
