import React, { useEffect, useState } from 'react'
import './style.css'
import { db } from './../../firebaseConfig';
import Link from './Link/index';
const Links = () => {
    const [links, setLinks] = useState([])
    useEffect(() => {
        db.collection('links').orderBy('priority', 'asc').onSnapshot(
            snapshot => {
                setLinks(snapshot.docs.map(
                    doc => (
                        {
                            title: doc.data().title,
                            url: doc.data().url,
                            id: doc.id
                        }
                    )
                ))
            }
        )
    }, [])
    return (
        <div className='links'>
            {
                links.map(({ title, url, id }) => (
                    <Link
                        key={id}
                        title={title}
                        url={url}
                    />
                ))
            }
        </div>
    )
}

export default Links