import React from 'react'
import btns from '../../data/headerBtns.json'
import socials from '../../data/socials.json'
import './style.css'
const Header = () => {
    return (
        <div className='header'>
            <img src="https://firebasestorage.googleapis.com/v0/b/doco-img.appspot.com/o/image%2FDoCoNation%20Full.png?alt=media&token=5c87d224-099b-43f9-ad58-8494972cb508" alt="DoCoNation Logo" width='220px' />
            <div className="btnHolder">
                {
                    btns.map(({ title, url, classes }) => (
                        <a
                            key={url}
                            href={url}
                            className={'btn' + classes}
                            style={{
                                marginRight: '4px'
                            }}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            {title}
                        </a>
                    ))
                }
            </div>
            <div className="socialIcons">
                {
                    socials.map(({ url, icon }) => (
                        <a
                            href={url}
                            className="socialIcon"
                            key={url}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <i className={icon}></i>
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default Header